<template>
  <div>
    <div class="main-content">
      <div class="main-manager">
        <el-input
          v-model="goodsPages.goods_name"
          placeholder="商品搜索（名称）"
          class="search-box"
          @input="changeGoodsList"
          @keyup.enter.native="searchGoodList"
        ></el-input>
        <el-button
          type="success"
          class="search-btn"
          @click="searchGoodList"
          style="margin-right:30px"
          >搜索</el-button
        >

        <span style="color:#999">分类筛选:</span>
        <el-select
          v-model="categoryList.id"
          placeholder="请选择商品分类"
          @change="changeCategory(categoryList.id)"
        >
          <el-option
            :label="items.category_name"
            :value="items.category_id"
            v-for="(items, index) in categoryList"
            :key="`categoryList_${index}`"
          ></el-option>
        </el-select>

        <div class="main-goodsOptions">
          <el-button type="primary" class="upload-goods" @click="goBack"
            >返回</el-button
          >
        </div>
      </div>
      <div class="content">
        <el-table
          :data="infoList"
          style="width: 100%; margin-top: 5px"
          height="1%"
          class="customTable"
        >
          <el-table-column
            prop="img_url"
            label="主图"
            align="center"
            style="width:100%"
            height="1%"
          >
            <template slot-scope="scope">
              <img class="goods-pic" :src="scope.row.img_url" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="goods_name" label="商品名称" align="center">
          </el-table-column>
          <el-table-column prop="category_name" label="类别名称" align="center">
          </el-table-column>
          <el-table-column prop="updated_at" label="删除时间" align="center">
          </el-table-column>
          <el-table-column prop="price" label="价格" align="center">
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template align="center" slot-scope="scope">
              <el-button
                slot="reference"
                type="text"
                size="small"
                style="margin-right:5px;color:red;font-size:14px"
                @click="handleClick_restore(scope.row)"
                >还原</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pages-center"
          @current-change="handleCurrentChange"
          :current-page.sync="goodsPages.currentPageNum"
          :page-size="goodsPages.eachPageNum"
          layout="prev, pager, next, jumper"
          :total="goodsPages.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  goods_delgoods,
  goods_goodsRecycleBin,
  goods_reCycleGoods,
  categoryList,
} from "@/config/apis";
export default {
  data() {
    return {
      infoList: [],
      goodsPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
        goods_name: "",
        category_id: "",
      },
      categoryList: [],
      lock: false,
    };
  },

  mounted() {
    this.getGoodsList();
    this.showCategoryList();
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    showCategoryList() {
      let params = {
        paging: "0",
      };
      categoryList(params).then((res) => {
        this.categoryList = res.data.list;
      });
    },
    changeCategory(value) {
      let params = {
        category_id: value,
      };
      goods_goodsRecycleBin(params).then((res) => {
        this.infoList = res.data.list;
        this.goodsPages.total = res.data.total;
      });
    },
    getGoodsList() {
      let params = {
        paging: "1",
        pageSize: this.goodsPages.eachPageNum,
        page: this.goodsPages.currentPageNum,
        category_id: this.goodsPages.category_id,
        goods_name: this.goodsPages.goods_name,
      };
      goods_goodsRecycleBin(params).then((res) => {
        this.infoList = res.data.list;
        this.goodsPages.total = res.data.total;
      });
    },

    handleClick_restore(row) {
      let params = {
        goods_id: row.goods_id,
      };
      this.$confirm("确定将该商品还原？", "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        goods_reCycleGoods(params)
          .then((res) => {
            this.$message.success(res.msg);
            this.getGoodsList();
          })
          .catch((err) => {});
      });
    },
    handleCurrentChange(val) {
      this.goodsPages.currentPageNum = val;
      this.getGoodsList();
    },
    searchGoodList() {
      if (this.lock) return false;
      this.lock = true;
      setTimeout(() => {
        this.getGoodsList();
        this.lock = false;
      }, 1000);
    },
    changeGoodsList(e) {
      if (e.length === 0) {
        this.getGoodsList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  margin: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  .main-manager {
    .search-box {
      width: 260px;
      margin: 20px 0 0 20px;
    }
    .search-btn {
      margin-left: 10px;
    }
    .main-goodsOptions {
      margin: 20px 20px 0 20px;
      float: right;
    }
  }
}
.content {
  flex: 1;
  height: 1%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.goods-pic {
  min-width: 200px;
  height: 100px;
}
</style>
